<template>
    <KCourse loader-class="MBcont"
             course-id="8"
             chapter-id=90
             chapterPayment="/Membership/Beginner2/MBCourseBeginnerChapterPayment.vue"
             title="Будуарная кукла" :items="items">
        <div class="MBtextcont">
            <h3>1. Создание туфелек. Часть 1</h3>
        </div>
                
        <VideoView video-id="d0ddce9f90d94f5692a8f94d697da275"/>

        <div class="MBtextcont">
            <h3>2.  Создание туфелек. Часть 2</h3>
        </div>

        <VideoView video-id="ef4d568f250e419fa51c9e2e09abda2b"/>

        <div class="MBtextcont">
            <h3>3. Собираем платье</h3>
        </div>

        <VideoView video-id="534feb0ff05d412b9d771aeab9c7e67b"/>

        <div class="MBtextcont">
            <h3>4. Делаем воротник</h3>
        </div>

        <VideoView video-id="8241b10505c04e008d9f89f0d1cfa169"/>

        <div class="MBtextcont">
            <h3>5. Декорируем </h3>
        </div>

        <VideoView video-id="1c079a0c4dfd437e914b7baba5868e2f"/>

        <div class="MBtextcont">
           <h3>Время выполнения: 4 часа</h3>
        </div>
    </KCourse>
</template>

<script>
    import VideoView from "../../Widgets/VideoView";
    import KCourse from "../../Widgets/KCourse";
    import chapters from './data.js';
    import KImage from "../../Widgets/KImage";

    export default {
        name: "MBCourseHead",
        components: {
            VideoView,
            KCourse,
            KImage
        },
        data() {
        return {
            items: chapters
        }
        },
        methods: {
            showImage(src, customClass) {
                this.$swal.fire({
                    customClass: customClass,
                    background: '#ffffff',
                    html: '<img width="100%" src="'+src+'"/>',
                    showCloseButton: true,
                    showConfirmButton: false,
                    // closeButtonHtml: "",
                })
            },
        }
    }
</script>

<style>

</style>